export default {
    data() {
        return {
            resultdata: { item: [], dataList: [] },
            historydata: { item: [], dataList: [] },
            divisorinfo: {},
           
            chartdetailDatalist:[],//每个图标的详细数据
            curmoveNum:1,
            curshowNum:3,
            shownumList:[{
              value:3,
              label:'展示3期'
            },{
              value:12,
              label:'展示12期'
            }],
            movenumList:[{
              value:1,
              label:'移动1期预测方案'
            },{
              value:3,
              label:'移动3期预测方案'
            },
            {
              value:12,
              label:'移动12期预测方案'
            }]
            
        }
    },
    methods: {
       cleardata(){
                this.historydata={
                  item:[],
                  dataList:[]
              }
              this.resultdata={
                  item:[],
                  dataList:[]
              }
              this.divisorinfo={};
              this.reportinfo={};
              this.reportDes={};
              this.linkfileList=[];
              this.colummList=[];
              this.columnChartlist=[];
              this.chartdetailDatalist=[];    
       }
    },
    computed: {
        productType(){
            const curprod=this.options.filter(o=>o.productId==this.productID);
            if(curprod.length>0){
                return curprod[0].productType
              
            }else{
                return ""
            }
        },
        historychart_opt() {
          //历史回顾图表
          
          if(this.historydata.item.length>0){

            let opt = JSON.parse(JSON.stringify(this.$defaultChartOption));
            opt.legend.data = this.historydata.item.map((o) => {
              return {
                name: o.name||'',
                unit: o.unit||'',
                itemStyle:{
                  opacity: o.id!='aiPE'&&o.id!='sciPE'&&o.id!='befarPE'?0:1
                }
              };
            });
            opt.tooltip.formatter = (params) => {
              var str = `<div>${params[0].axisValue}</div>`;
              params.forEach((val)=>{
                if(this.$numFormat(val.data)){
                    let item=this.historydata.item[val.seriesIndex]
                    if(item.id=='ai'||item.id=='sci'||item.id=='befar'){
                      if(!this.historydata.dataList.slice(0,val.dataIndex).every(v=>!v[item.id])){
                        str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
                        }
                  }else{
                        str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
                  }
                    
                }
            })
              return str;
            };
            opt.yAxis=[
              {
                type: 'value',
                splitLine: {
                  lineStyle: {
                    type:'dashed'
                  }
                },
                scale:true,
              },
              {
                type: 'value',

                splitLine:{
                  show:false
                },
                axisLabel:{
                  formatter: '{value}%'
                },
                min:0,
                max:100
              }
            ]
            opt.xAxis.data = this.historydata.dataList.map((o) => {
              return o.date;
            });
            const baritems=['aiPE','sciPE','befarPE'];
            opt.series = this.historydata.item.map((o) => {
              return {
                name: o.name,
                type:baritems.findIndex(j=>j==o.id)<0?"line":"bar",
                connectNulls: true,
                data: this.historydata.dataList.map((item) => {
                  return item[o.id];
                }),
                lineStyle: o.id=='his'?{}:{
                    type: 'dashed'
                },
                yAxisIndex:baritems.findIndex(j=>j==o.id)>=0?1:0,
                itemStyle:baritems.findIndex(j=>j==o.id)<0?{}:{ 
                  borderRadius:[10,10,0,0],
                  opacity:0.5
                },

                barMinHeight:1
              };
            });
            return opt;
          }else{
            return null;
          }
          //console.log(opt)
         
        },
        Resultchart_opt() {
          //预测结果图表
        
          if(this.resultdata.item.length>0){
            let opt = JSON.parse(JSON.stringify(this.$defaultChartOption));
            opt.legend.data = this.resultdata.item.map((o) => {
              return {
                name: o.name,
                unit: o.unit,
                itemStyle: {
                  opacity: 0,
                },
              };
            });
           
            opt.xAxis.boundaryGap = true;
            opt.tooltip.formatter = (params) => {
              var str = `<div>${params[0].axisValue}</div>`;
              params.forEach((val)=>{
                  if(this.$numFormat(val.data)){
                      let item=this.resultdata.item[val.seriesIndex]
                      if(item.id!='his'){
                          if(!this.$numFormat(this.resultdata.dataList[val.dataIndex]['his'])||!params.some(v=>v.seriesId=='his')){
                          str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
                          }
                      }else{
                          str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
                      }
                  }
              })
              return str;
            };
            opt.xAxis.data = this.resultdata.dataList.map((o) => {
              return o.date;
            });
            opt.series = this.resultdata.item.map((o) => {
              return {
                name: o.name,
                type: "line",
                id:o.id,
                connectNulls: true,
                data: this.resultdata.dataList.map((item) => {
                  return item[o.id];
                }),
                lineStyle:
                  o.id == "his" ? {} : {
                        type: "dashed",
                      },
              };
            });
            return opt;
          }else{
            return null
          }
          
          
          
        },
    
        columnChartdatalist(){//栏目的图表
            const templist=[];
            
            this.chartdetailDatalist.sort((a,b)=>{return a.id - b.id;})
            for(let item of this.chartdetailDatalist){
              
              if(item.reportColumnTable && item.reportColumnTable.id){
                let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) ;
                opt.title={
                  subtext: item.reportColumnTable.tablename?item.reportColumnTable.tablename:'',
                  left: "center",
                  top: "-10",
                  subtextStyle:{
                    fontSize :'14'
                  },
                 
                }
                        opt.legend.data=item.items.map(o=>{
                            return{
                                name:o.dataName,
                                itemStyle:{
                                    opacity:0
                                }
                            }
                        })
                        opt.yAxis=[{
                            type:'value',
                            splitLine: {
                              lineStyle: {
                                type:'dashed'
                              }
                            }
                        },{
                            type:'value',
                            splitLine:{
                              lineStyle: {
                                type:'dashed'
                              },
                              show:item.items.findIndex(o=>o.dataAxis=='left')>=0?false:true
                            }
                        }]
                        let datelist=[]
                        opt.series=item.items.map(o=>{
                            //将所有日期合并
                            const temp=item.dataList.filter(j=>j[o.id])[0];
                            const tempdate=temp[o.id]?temp[o.id].map(i=>{return  this.$dateFormat(i.datadate,'yyyy-MM-dd')}):[];
                            tempdate.forEach(o=>{
                             if(!datelist.some(j=>j==o)){
                                datelist.push(o)
                             }
                            })
                            datelist.sort((a,b)=>{return Date.parse(a) - Date.parse(b)})
                           // console.log(datelist);
                            //日期处理结束
                            return{
                                name:o.dataName,
                                type:o.dataType,
                                connectNulls: true,
                                yAxisIndex:o.dataAxis=='left'?0:1,
                                data:  temp[o.id]?temp[o.id].map(i=>{return i.value}):[],
                            }
                        })
                        opt.xAxis.data=datelist;
                        templist.push({id:item.reportColumnTable.id,chartopt:opt,columnid:item.reportColumnTable.columnid});
              } 
               
               
            }
            return templist;
        }
      },
};
