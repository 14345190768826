<template>
  <div>
      <el-table
        :border=true
        size="medium"
        :header-cell-style="headerCellStyle"
        :span-method="cellMerge"
        :data="tableList">
          <div slot="empty"><el-empty :image-size="150"></el-empty></div>
          <template v-if="tableList.length">
            <el-table-column label="时间" width="120">
              <template slot-scope="props">{{props.row.firstNode}}</template>
            </el-table-column>
            <el-table-column width="120">
              <template slot-scope="props">{{props.row.secondNode}}</template>
            </el-table-column>
            <!-- <el-table-column v-for="(item,index) in tableList[0].dataList" :key="index" :label="item.forecastDateShow" show-overflow-tooltip>
              <template slot-scope="props">{{props.row.dataList[index].nodeList.desc}}</template>
            </el-table-column> -->
            <el-table-column v-for="(item,index) in tableList[0].dataList" :key="index" :label="item.forecastDateShow">
              <template slot-scope="props">
                <template v-if="props.row.dataList[index].nodeList.every(v=>{return !v.desc})">-</template>
                <template v-else><div v-for="(item2,index2) in props.row.dataList[index].nodeList" :key="index2">{{ item2.desc }}</div></template>  
              </template>
            </el-table-column>
          </template>
      </el-table>
  </div>
</template>

<script>
export default {
  name: 'SubNav',
  props:{
    data:{
      type:Array,
      default: function() {
        return []  
      }
    }
  },
  data() {
    return {
      spanArr_1:[],
      pos_1:0,
      spanArr_2:[],
      pos_2:0
    }
  },
  computed:{
    tableList(){
      let list=[]
      for(let first of this.data){
        for(let second of first.children){
          // let nodeLength =Math.max.apply(null, second.dataList.map(val=>{return val.nodeList.length})); 
          // for(let i=0;i<nodeLength;i++){
          //   list.push({firstNode:first.firstNode,secondNode:second.secondNode,dataList:second.dataList.map(val=>{return {forecastDateShow:val.forecastDateShow,nodeList:val.nodeList[i]||''}})})
          // }
          list.push({firstNode:first.firstNode,secondNode:second.secondNode,dataList:second.dataList})
        }
      }
      return list
    }
  },
  methods:{
    headerCellStyle({ column, rowIndex, columnIndex }) {
      // 第一步：设置表头的第0列暂不操作，将地1列隐去使其消失
      if ((columnIndex == 1)) {
        return { display: "none" };
      }
      // 第二步， 由于1、2列没有了，后续列就会贴上来（后续列往左错位问题）
      if ((rowIndex == 0) & (columnIndex == 0)) {
        // 解决后续列错位问题，就是将隐去的第1、2列的位置再补上，通过第0列来补
        this.$nextTick(() => {
          // 原来第0列只占据一个位置，现在要去占据三个位置。即占据三列，即设置为横向三个单元格
          document.querySelector(`.${column.id}`).setAttribute("colspan", "2");
          // 这里的column.id实际是dom元素的class，故用点.不用井#，可审查dom验证
          // 通过设置原生的colspan属性，让原来的第一列只占据一个单元格的表头占据3个单元格即可
        });
      }
    },
    //表格合并规则
    getSpanArr(data) {//table记录每一行设置的合并数
      this.spanArr_1=[];
      this.pos_1=0;
      this.spanArr_2=[];
      this.pos_2=0;
      for (let i = 0; i < data.length; i++) {
        if (i === 0) {
              this.spanArr_1.push(1);
              this.pos_1 = 0;
              this.spanArr_2.push(1);
              this.pos_2 = 0
        } else {
          // 判断当前元素与上一个元素是否相同
          if (data[i].firstNode === data[i - 1].firstNode) {
            this.spanArr_1[this.pos_1] += 1;
            this.spanArr_1.push(0);
          } else {
            this.spanArr_1.push(1);
            this.pos_1 = i;
          }
          if (data[i].secondNode === data[i - 1].secondNode) {
            this.spanArr_2[this.pos_2] += 1;
            this.spanArr_2.push(0);
          } else {
            this.spanArr_2.push(1);
            this.pos_2 = i;
          }
        }
      }
    },
    cellMerge({rowIndex, columnIndex}) {//设置table行合并的方法
        let row=this.tableList[rowIndex]
        if (columnIndex == 0) {
          let _row = this.spanArr_1[rowIndex];
          let _col = (_row > 0&&_row!=this.spanArr_2[rowIndex])||(_row > 0&&_row==this.spanArr_2[rowIndex]&&row.firstNode!=row.secondNode) ? 1 :_row > 0&&_row==this.spanArr_2[rowIndex]&&row.firstNode==row.secondNode ? 2:0;
          return {
            rowspan: _row,
            colspan: _col
          }
        }
        if (columnIndex == 1) {
          let _row = this.spanArr_2[rowIndex];
          let _col = _row > 0 &&(_row!=this.spanArr_1[rowIndex]||row.firstNode!=row.secondNode) ? 1 :0;
          return {
            rowspan: _row,
            colspan: _col
          }
        }
    },
  },
  created(){
   
  },
  mounted(){
    this.getSpanArr(this.tableList)
  },
  destroyed(){
   
  },
  watch: {
    tableList(val){
      this.getSpanArr(val)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>

</style>
