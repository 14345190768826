<template>
    <div>
      <div class="margin-bottom-10 font-size-12 color-sub">
        模型因子自估是模型基于因子联合预测方式，以价格预测为目标，通过对历史数据评估和拟合，推演出的因子结果，不作为因子供需预测的依据和参考。
      </div>
        <el-table
                                    :data="divisorTbaledata"
                                    style="width: 100%"
                                    class=""
                                   
                                    >
                                    <div slot="empty"><el-empty :image-size="150"></el-empty></div>
                                    <el-table-column
                                        label="因子"
                                        width="280px"
                                        fixed
                                       >
                                       <template v-slot="scope">
                                            <div class="link-text" @click="getDivisorChart(scope.row)">{{scope.row.divisorName}}</div>
                                      </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="unit"
                                        label="单位"
                                       
                                       >
                                    </el-table-column>
                                    <template v-if="productType=='default' &&  divisorinfo[curmoveNum]">
                                    <el-table-column  v-for="(item,index) of divisorinfo[curmoveNum].dataList.slice(0,this.datacolumNum)" :label="item.dateCycleShow" align="center"  :key="index" label-class-name="haschild">
                                      
                                            <el-table-column
                                            align="center"
                                            :prop="'sciValue'+index"
                                            label="卓创"
                                            >
                                            <template v-slot="scope">
                                                {{scope.row['sciValue'+index]}}
                                            
                                            </template>
                                          </el-table-column>
                                            <el-table-column
                                            align="center"
                                            :prop="'aiValue'+index"
                                            label="自估"
                                            >
                                            <template v-slot="scope">
                                                {{scope.row['aiValue'+index]}}
                                            
                                            </template>
                                            </el-table-column>
                                            <el-table-column
                                            align="center"
                                            :prop="'befarValue'+index"
                                            label="滨化"
                                            > 
                                            <template v-slot="scope">
                                            {{scope.row['befarValue'+index]}}
                                            </template>
                                            </el-table-column>
                                     
                                    </el-table-column>
                                  </template>
                                  <template v-if="productType!='default' &&  divisorinfo[curmoveNum]" >
                                    <el-table-column  v-for="(item,index) of divisorinfo[curmoveNum].dataList" :label="item.dateCycleShow" align="center"  :key="index" >
                                        <template  v-slot="scope">
                                            {{scope.row['customValue'+index]}}
                                        </template>
                                    </el-table-column>
                                         
                                   </template>
                                    <el-table-column
                                        prop="contribution"
                                        label="因子贡献度"
                                        v-if="curmoveNum==1"
                                       >
                                    </el-table-column>
                                </el-table>
                                 <!-- 因子数据图详情弹窗 -->
   <el-dialog custom-class="custom-dialog noheadercustom-dialog" append-to-body :close-on-click-modal="false" width="80%" top="15vh" :visible.sync="divisorData.visible">
      <div v-loading="divisorData.loading"  >
        <el-tabs v-model="activedivisorTab" >
            <el-tab-pane label="查看因子" name="first">
                <div class="flex-column" style="height: calc(70vh - 180px); overflow-y: auto;">
                  <div  v-show="productType=='default'">
                    <div><span>卓创赋值说明：</span>{{divisorData.info.sciReason}}</div>
                    <div class="margin-top-5"><span>滨化赋值说明：</span>{{divisorData.info.befarReason}}</div>
                  </div>
                  <Chart :option="divisorChart" class="flex-auto"></Chart>
                </div>
            </el-tab-pane>
            <el-tab-pane label="数据详情" name="second">
                <div  style="height: calc(70vh - 180px); overflow-y: auto;">
                    <el-table
                :data="divisorData.info?divisorData.info.dataList:[]"
                style="width: 100%"
                >
                <el-table-column                                                                                         
                    :prop="productType=='default'?'dateCycleShow':'dataCycle'"
                    label="日期"
                    >
                </el-table-column>
                <el-table-column v-for="item of divisorData.info.items"
                    :prop="item.id"
                    :label="item.name"
                    :key="item.id"
                    >
                </el-table-column>
                 </el-table>
                </div>
               
            </el-tab-pane>
            
        </el-tabs>
      </div>
    </el-dialog>
    </div>
</template>
<script>
import Chart from "@/components/Chart";
export default {
    name:'divisorTablecom',
    data() {
        return {
          divisorData: {
                //因子图表详情
                loading: false,
                visible: false,
                info: {},
              },
          activedivisorTab:"first",
          divisorActive:{},//当前编辑的因子
        }
    },
    components:{
        Chart
     },
    props:{
        curmoveNum:{
            default:1
        },
        curshowNum:{
          default:3
        },
        divisorinfo:{
          type:Object,
          default: function() {
            return {}  
          }
        },
        productType:{
            default:'default'
        },
        productID:{
          default:''
        },
        activeFreq:{
          default:''
        },
        target:{
          default:''
        }
    },
    methods:{
 //获取因子走势图详情
        getDivisorChart(divisor){
            this.divisorActive=divisor
            this.activedivisorTab="first"
            // this.divisorData={
            //     loading:false,
            //     visible:true,
            //     :{}
            // }
           
            this.divisorData.info=this.divisorinfo[this.curmoveNum].item.filter(o=>{return o.divisorId==divisor.divisorId})[0]?this.divisorinfo[this.curmoveNum].item.filter(o=>{return o.divisorId==divisor.divisorId})[0].divisorDetail:{};
            this.divisorData.loading=false;
            this.divisorData.visible=true;
            // if(this.productType=='default'){
            //     this.$instance.get('/assignment/getDivisorDetail',{
            //       params: {
            //           ...this.$store.state.basicParams,
            //           productId:this.productID,
            //           modelFreq:this.activeFreq,
            //           divisorId:divisor.divisorId,
            //           isOut:divisor.isOut,
            //           targetId:this.target
            //       },
            //     })
            //     .then(res=>{
            //         this.divisorData.loading=false;
            //         if(res.data.code==0){
            //         this.divisorData.info=res.data.info;
            //         }else{
            //         this.divisorData.info={}
            //         }
            //     })
            //     .catch(()=> {
            //         this.divisorData.loading=false;
            //         this.divisorData.info={}
            //     }); 
            // }else{
              
            //   this.$instance.get('/befarCustomModel/getDivisorDetail',{
            //       params: {
            //           ...this.$store.state.basicParams,
            //           customModelId:divisor.customModelId,
            //           modelFreq:this.activeFreq,
            //           divisorId:divisor.divisorId,
                     
            //       },
            //     })
            //     .then(res=>{
            //         this.divisorData.loading=false;
            //         if(res.data.code==0){
            //         this.divisorData.info=res.data.info;
            //         }else{
            //         this.divisorData.info={}
            //         }
            //     })
            //     .catch(()=> {
            //         this.divisorData.loading=false;
            //         this.divisorData.info={}
            //     }); 
            // }
            
        },
    },
    computed:{
        datacolumNum(){
          return this.divisorinfo[this.curmoveNum].dataList.length>=this.curshowNum?this.curshowNum:this.divisorinfo[this.curmoveNum].dataList.length
        },
        divisorTbaledata() {
          //因子表格
          let templist = [];
          if(this.divisorinfo[this.curmoveNum]){
            console.log(this.curmoveNum);
            templist = this.divisorinfo[this.curmoveNum].item.map((o) => {
              let temp = {
                divisorName: o.divisorName,
                id: o.id,
                unit: o.unit,
                divisorId: o.divisorId,
                isOut: o.isOut,
                customModelId:o.customModelId
              };
              for (let i = 0; i < this.divisorinfo[this.curmoveNum].dataList.length && i < this.curshowNum; i++) {
                temp["sciValue" + i] = this.$numFormat(this.divisorinfo[this.curmoveNum].dataList[i][o.divisorId].sciValue,2,'-');
                temp["aiValue" + i] = this.$numFormat(this.divisorinfo[this.curmoveNum].dataList[i][o.divisorId].aiValue,2,'-');
                temp["befarValue" + i] =this.$numFormat( this.divisorinfo[this.curmoveNum].dataList[i][o.divisorId].befarValue,2,'-');
                temp["customValue" + i] =this.$numFormat( this.divisorinfo[this.curmoveNum].dataList[i][o.divisorId].customValue,2,'-');
                if(i==0){//因子贡献度取第一个
                  temp.contribution=this.divisorinfo[this.curmoveNum].dataList[i][o.divisorId].contribution?this.$numFormat(this.divisorinfo[this.curmoveNum].dataList[i][o.divisorId].contribution,2,'-')+'%':'-'
                }
               
              }
              return temp;
            });
          }
          
          return templist;
        },
        divisorChart() {
          let chartData = this.divisorData.info;
          if (chartData.items) {
            let opt = JSON.parse(JSON.stringify(this.$defaultChartOption));
            opt.yAxis.name = this.divisorActive.unit;
            opt.xAxis.boundaryGap = false;
            opt.xAxis.data = chartData.dataList.map((v) => {
              return v.dateCycleShow||v.dataCycle;
            });
            // opt.dataZoom.startValue=opt.xAxis.data.length-15
            opt.legend.data = chartData.items.map((v) => {
              return {
                name: v.name,
                unit: v.unit,
              };
            });
            opt.series = chartData.items.map((v) => {
              return {
                name: v.name,
                type: "line",
                connectNulls: true,
                lineStyle: {
                  type: v.id == "hisValue" || v.id =="his"? "solid" : "dashed",
                },
                data: chartData.dataList.map((vv) => {
                  return vv[v.id];
                }),
              };
            });
            opt.tooltip.formatter = (params) => {
              let str = `<div>${params[0].axisValue}</div>`;
              params.forEach((val) => {
                if (this.$numFormat(val.data)) {
                  let item = chartData.items[val.seriesIndex];
                  if (item.id != "hisValue" && item.id != "his") {
                    if (
                      !chartData.dataList
                        .slice(0, val.dataIndex)
                        .every((v) => !v[item.id])
                    ) {
                      str += `<div>${val.marker}${
                        val.seriesName
                      }：${this.$numFormat(val.data, 2)}${
                        opt.legend.data[val.seriesIndex].unit
                      }</div>`;
                    }
                  } else {
                    str += `<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`;
                  }
                }
              });
              return str;
            };
            return opt;
          } else {
            return null;
          }
        },
    }
}
</script>
<style >
 .noheadercustom-dialog .el-dialog__header{
        padding: 0;
    }
</style>
